import { SquiggleLink } from "@/components/shared/squiggle-link";
import { useTheme, Box, Typography } from "basta-ui";
import { useRouter } from "next/router";

const Footer = () => {
  const router = useRouter();
  const theme = useTheme();
  return (
    <Box
      sx={{
        paddingBottom: "80px",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          padding: "40px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          [theme.breakpoints.up("sm")]: {
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          },
        }}
      >
        <Typography
          level="h4"
          fontWeight={"bold"}
          sx={{ fontSize: 32 /* HARDCODED VALUE, REMOVE LATER!!! */ }}
        >
          hi@basta.ai
        </Typography>
        <Box p={2} />
        <Typography level="body-md">Skógarhlíð 12, 105 Reykjavík</Typography>
      </Box>

      <Box p={2} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            [theme.breakpoints.up("sm")]: {
              justifyContent: "start",
            },
          }}
        >
          <div onClick={() => router.push("/privacy-policy")}>
            <SquiggleLink
              link={false}
              text="Privacy Policy"
              level="body-md"
              url=""
            />
          </div>
        </Box>
        <Box p={2} />
        <Typography level="body-sm">
          Basta Ventures ehf. © 2023, All rights reserved{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
