import { useRef } from "react";
import { Box, Container, useTheme } from "basta-ui";
import { ThemeSwitch } from "@/components/pages/landing/theme-switch";
import AuctionAnywhere from "@/components/pages/landing/auction-anywhere";
import BetaReleaseDate from "@/components/pages/landing/beta-release-date";
import Footer from "@/components/pages/landing/footer";
import SignUpForDemo from "@/components/pages/landing/sign-up";

export default function Landing() {
  const theme = useTheme();
  const signUpRef = useRef<HTMLDivElement>(null);

  const scrollToSignUp = () => {
    signUpRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        [theme.getColorSchemeSelector("light")]: {
          background: theme.vars.palette.gradients.gradient1[100],
        },
        [theme.getColorSchemeSelector("dark")]: {
          background: theme.vars.palette.gradients.gradient1[300],
        },
        background:
          "linear-gradient(88.54deg, rgba(63, 106, 176, 0.1) 1.16%, rgba(237, 92, 52, 0.1) 96.57%)",
      }}
    >
      <ThemeSwitch />
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "100%",
          padding: "32px",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          [theme.breakpoints.up("sm")]: { padding: "60px" },
          [theme.breakpoints.up("md")]: { gap: "96px" },
        }}
      >
        <AuctionAnywhere scrollToSignUp={scrollToSignUp} />

        <BetaReleaseDate />

        <Box ref={signUpRef} py={5}>
          <SignUpForDemo />
        </Box>

        <Footer />
      </Container>
    </Box>
  );
}
