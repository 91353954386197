import { useColorScheme as useMuiColorScheme } from "@mui/material/styles";
import { useColorScheme as useJoyColorScheme } from "@mui/joy";

export const useColorScheme = () => {
  const { setMode: setMuiMode } = useMuiColorScheme();
  const { mode: joyMode, setMode: setJoyMode } = useJoyColorScheme();

  const setMode = (mode: "dark" | "light") => {
    setMuiMode(mode);
    setJoyMode(mode);
  };

  return { mode: joyMode, setMode };
};
