import useSendEmail from "@/hooks/pages/landing/use-send-email";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
  useTheme,
} from "basta-ui";

const SignUpForDemo = () => {
  const theme = useTheme();
  const { email, loading, status, success, sendEmail, setEmail } =
    useSendEmail();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: "8px",
        flexDirection: "column",
        position: "relative",
        marginBottom: "16px",
        [theme.breakpoints.up("sm")]: {
          gap: "24px",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Typography
        level="h1"
        sx={{ fontSize: 48 /* HARDCODED VALUE, REMOVE LATER!!! */ }}
      >
        Sign up for a demo
      </Typography>

      <Typography level="body-lg">
        Enter your email here for the demo or for inquires regarding our beta
        launch.
      </Typography>
      <Box p={1} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",

          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
          },
        }}
      >
        <Input
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            height: 64,
            [theme.breakpoints.up("sm")]: {
              width: 352,
            },
          }}
          fullWidth
          variant="outlined"
        />
        <Button
          onClick={sendEmail}
          fullWidth
          sx={{
            height: 64,
            fontSize: "18px", // !!!REMOVE THIS HARDCODED VALUE LATER!!!!
            [theme.breakpoints.up("sm")]: {
              width: 160,
            },
          }}
        >
          {loading ? <CircularProgress /> : "Submit"}
        </Button>
      </Box>
      {status && (
        <Box pt={1} sx={{ position: "absolute", bottom: -65 }}>
          <Alert color={success ? "success" : "danger"}>{status}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default SignUpForDemo;
