import { CssVarsThemeOptions } from "@mui/joy";

const baseTheme: Pick<CssVarsThemeOptions, "typography" | "components"> = {
  typography: {
    "body-md": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "150%",
    },
    "body-sm": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "150%",
    },
    "body-xs": {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "150%",
    },
    h1: {
      fontSize: "4rem",
      lineHeight: "130%",
      fontWeight: 700,
    },
    h2: {
      fontSize: "3rem",
      lineHeight: "130%",
      fontWeight: 700,
    },
    h3: {
      fontSize: "2.5rem",
      lineHeight: "130%",
      fontWeight: 700,
    },
    h4: {
      fontSize: "2rem",
      lineHeight: "120%",
      fontWeight: 700,
    },
    "title-lg": {
      fontSize: "1.5rem",
      lineHeight: "120%",
      fontWeight: 700,
    },
    "title-md": {
      fontSize: "1.25rem",
      lineHeight: "150%",
      fontWeight: 700,
    },
  },

  components: {
    JoySheet: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          ":focus-visible": {
            outline: "none",
            boxShadow: `0px 0px 0px 4px rgba(${theme.vars.palette.warning.mainChannel})`,

            "&::before": {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              border: `2px solid ${theme.vars.palette.background.body}`,
              outline: "none",
              borderRadius: 8,
            },
          },
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: 64,
          borderRadius: 8,
          fontSize: "1.125rem",
          fontWeight: 500,
          lineHeight: "150%",
          ":focus-visible": {
            outline: "none",
            boxShadow: `0px 0px 0px 4px rgba(${theme.vars.palette.warning.mainChannel})`,

            "&::before": {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: `2px solid ${theme.vars.palette.background.body}`,
              outline: "none",
              borderRadius: 8,
            },
          },

          ...(ownerState.variant === undefined && {
            background: theme.vars.palette.primary[500],
          }),

          ...(ownerState.variant === "solid" && {
            background: theme.vars.palette.primary[500],
            "&:hover": {
              background: `linear-gradient(54.83deg, ${theme.vars.palette.success[500]} 17.77%, ${theme.vars.palette.primary[500]} 97.5%)`,
              boxShadow: `4px 4px 14px rgba(${theme.vars.palette.primary.mainChannel} / 0.22)`,
            },
          }),

          ...(ownerState.variant === "outlined" && {
            borderColor: theme.vars.palette.primary.mainChannel,
            "&:hover": {
              backgroundColor: theme.vars.palette.background.body,
              borderColor: theme.vars.palette.primary.mainChannel,
              boxShadow: `4px 4px 14px rgba(${theme.vars.palette.primary.mainChannel} / 0.22)`,
            },
          }),

          ...(ownerState.variant === "soft" && {
            [theme.getColorSchemeSelector("light")]: {
              backgroundColor: theme.palette.primary[50],
              "&:hover": {
                background: `linear-gradient(82.91deg, ${theme.vars.palette.primary[100]} 20.49%, ${theme.vars.palette.primary[50]} 96.99%)`,
                boxShadow: `4px 4px 14px rgba(${theme.vars.palette.primary.mainChannel} / 0.22)`,
              },
            },
            [theme.getColorSchemeSelector("dark")]: {
              backgroundColor: theme.palette.primary[800],
              "&:hover": {
                background: `linear-gradient(81.15deg, ${theme.vars.palette.primary[800]} 0%, ${theme.vars.palette.primary[500]} 102.43%)`,
              },
            },
          }),

          ...(ownerState.size === "sm" && {
            height: 40,
            fontSize: "1rem",
            fontWeight: 500,
          }),
        }),
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          maxWidth: 567,
          width: "100%",
          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: theme.vars.palette.common.white,
          },
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: 64,
          borderRadius: 8,

          [theme.getColorSchemeSelector("dark")]: {
            ["& button"]: {
              color: theme.vars.palette.common.white,
            },
          },

          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: theme.palette.common.white,
            ["& button"]: {
              color: theme.vars.palette.primary[400],
            },
          },
        }),
      },
    },
    JoyCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          boxShadow: "none",
          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: theme.palette.common.white,
          },
        }),
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: 8,
          [theme.getColorSchemeSelector("light")]: {
            ...(ownerState.variant === "solid" && {
              backgroundColor: theme.vars.palette.common.white,
              color: theme.vars.palette.primary[900],
            }),
          },
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: 56,
          borderRadius: 8,
          outline: 0,
          padding: "0px 16px",
          fontSize: "16px",
          borderColor: theme.vars.palette.primary[50],
          [theme.getColorSchemeSelector("dark")]: {
            ...(ownerState.variant === "outlined" && {
              borderColor: theme.vars.palette.primary[600],
            }),
          },

          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: theme.vars.palette.common.white,
            color: theme.vars.palette.primary[900],
          },
          ['& > [class*="Decorator"] > svg']: {
            width: 16,
            strokeWidth: 2,
            color: theme.vars.palette.primary[600],
          },
        }),
      },
    },
    JoyAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    JoyBox: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: theme.vars.palette.common.white,
          },
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: theme.vars.palette.common.black,
          },
        }),
      },
    },

    JoyOption: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: theme.vars.palette.common.white,
            color: theme.vars.palette.primary[400],
          },
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: theme.vars.palette.common.black,
            color: theme.vars.palette.common.white,
          },
        }),
      },
    },

    JoyTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "12px",
          color: theme.palette.neutral[400],
          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: theme.palette.primary[500],
            fontWeight: "bold",
          },
        }),
      },
    },

    JoyTooltip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
          padding: "16px",
          backgroundColor: theme.palette.primary[50],
          border: `0.5px solid ${theme.palette.common.black}`,
          color: theme.palette.common.black,
        }),
      },
    },
  },
};

export default baseTheme;
