import { PaletteRange } from "@mui/joy";

export type PaletteRangeGradient = Pick<
  PaletteRange,
  100 | 200 | 300 | 400 | 500 | 600
>;

declare module "@mui/joy/styles" {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
    gradients: {
      gradient1: PaletteRangeGradient;
      gradient2: PaletteRangeGradient;
      gradient3: PaletteRangeGradient;
      gradient4: PaletteRangeGradient;
      gradient5: PaletteRangeGradient;
      gradient6: PaletteRangeGradient;
      radial1: PaletteRangeGradient;
    };
  }
}

// Joy
export { getInitColorSchemeScript } from "@mui/joy";
export { useTheme } from "@mui/joy";
export { CssBaseline } from "@mui/joy";
export { CssVarsProvider } from "@mui/joy";
export { styled } from "@mui/joy";
export type { TypographySystem } from "@mui/joy";
export { Button } from "@mui/joy";
export { Box } from "@mui/joy";
export { Container } from "@mui/joy";
export { Typography } from "@mui/joy";
export { Grid } from "@mui/joy";
export { Input } from "@mui/joy";
export { MenuItem } from "@mui/joy";
export { Menu } from "@mui/joy";
export { Tooltip } from "@mui/joy";
export { IconButton } from "@mui/joy";
export { Modal } from "@mui/joy";
export { ModalDialog } from "@mui/joy";
export { Stack } from "@mui/joy";
export { Card } from "@mui/joy";
export { Sheet } from "@mui/joy";
export { Alert } from "@mui/joy";
export { CircularProgress } from "@mui/joy";
export { Link } from "@mui/joy";
export { List } from "@mui/joy";
export { ListItem } from "@mui/joy";
export { Textarea } from "@mui/joy";
export { Divider } from "@mui/joy";
export { Avatar } from "@mui/joy";
export { CardContent } from "@mui/joy";
export { ModalClose } from "@mui/joy";
export { Select } from "@mui/joy";
export { Option } from "@mui/joy";
export { Tabs } from "@mui/joy";
export { Tab } from "@mui/joy";
export { TabList } from "@mui/joy";
export { TabPanel } from "@mui/joy";
export { Dropdown } from "@mui/joy";
export { MenuButton } from "@mui/joy";
export { Checkbox } from "@mui/joy";

export { keyframes } from "@emotion/react";

// Mui
export { LicenseInfo } from "@mui/x-date-pickers-pro";
export type { DateRange } from "@mui/x-date-pickers-pro";
export type NonEmptyDateRange<TDate> = [TDate, TDate];
export { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
export { TimePicker } from "@mui/x-date-pickers-pro";
export { LocalizationProvider } from "@mui/x-date-pickers-pro";
export { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
export { NoSsr } from "@mui/base";
export type { SxProps } from "@mui/system";
export { Accordion } from "@mui/material";
export { AccordionDetails } from "@mui/material";
export { AccordionSummary } from "@mui/material";

// Theme
export { default as BastaThemeProvider } from "./theme";
export { useColorScheme } from "./theme/use-color-scheme";

// Icons
export { ChevronLeftIcon } from "@heroicons/react/24/outline";
export { ChevronRightIcon } from "@heroicons/react/24/outline";
export { ChevronDownIcon } from "@heroicons/react/24/outline";
export { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
export { XMarkIcon } from "@heroicons/react/24/solid";
export { Bars3Icon } from "@heroicons/react/24/solid";
export { MoonIcon } from "@heroicons/react/24/solid";
export { SunIcon } from "@heroicons/react/24/solid";
export { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
export { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
export { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
export { ArrowDownIcon } from "@heroicons/react/24/outline";
export { Cog8ToothIcon } from "@heroicons/react/24/outline";
export { SpeakerWaveIcon } from "@heroicons/react/24/outline";
export { PlusIcon } from "@heroicons/react/24/solid";
export { EyeIcon } from "@heroicons/react/24/outline";
export { EyeSlashIcon } from "@heroicons/react/24/outline";
export { CurrencyDollarIcon } from "@heroicons/react/24/outline";
export { CalendarIcon } from "@heroicons/react/24/outline";
export { PencilSquareIcon } from "@heroicons/react/24/outline";
export { TrashIcon } from "@heroicons/react/24/outline";
export { QrCodeIcon } from "@heroicons/react/24/outline";
export { GlobeAltIcon } from "@heroicons/react/24/outline";
export { ArrowRightIcon } from "@heroicons/react/24/outline";
export { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
export { InformationCircleIcon } from "@heroicons/react/24/outline";
export { LinkIcon } from "@heroicons/react/24/outline";
export { ShareIcon } from "@heroicons/react/24/outline";
export { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
export { CheckIcon } from "@heroicons/react/24/outline";
export { ExclamationCircleIcon } from "@heroicons/react/24/outline";
export { HandRaisedIcon } from "@heroicons/react/24/outline";

//
