import { Box, Link, Typography, TypographySystem, useTheme } from "basta-ui";
import NextLink from "next/link";

export const SquiggleLink: React.ElementType = ({
  text,
  url,
  level,
  color,
  darkModeColor,
  disabled = false,
}: {
  text: string;
  url: string;
  level: "inherit" | keyof TypographySystem | undefined;
  color?: string;
  darkModeColor?: string;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Link
      href={url}
      rel="noreferrer"
      sx={{
        position: "relative",
        display: "inline-flex",
        overflow: "hidden",
        cursor: "pointer",
        textDecoration: "none",
        outline: "none",
        ":hover": {
          textDecoration: "none",
          svg: {
            transform: "translate3d(-66.6%, 0, 0)",
          },
        },
      }}
      component={NextLink}
      disabled={disabled}
    >
      <>
        <Typography
          level={level}
          sx={{
            [theme.getColorSchemeSelector("light")]: {
              color: color,
            },
            [theme.getColorSchemeSelector("dark")]: {
              color: darkModeColor ? darkModeColor : color,
            },
          }}
        >
          {text}
        </Typography>

        <Box
          component="svg"
          width="300%"
          height="100%"
          viewBox="0 0 1200 60"
          preserveAspectRatio="none"
          fill={color}
          sx={{
            [theme.getColorSchemeSelector("light")]: {
              stroke: color || theme.vars.palette.text.primary,
            },
            [theme.getColorSchemeSelector("dark")]: {
              stroke: darkModeColor
                ? darkModeColor
                : color || theme.vars.palette.text.primary,
            },

            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            fill: "none",
            strokeWidth: "2px",
            transition: "transform 0.7s",
            transitionTimingFunction: "cubic-bezier(0, 0.25, 0.5, 1)",
          }}
        >
          <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
        </Box>
      </>
    </Link>
  );
};
