import { useState } from 'react'

type UseSendEmail = {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  status: string
  success: boolean | null
  sendEmail: () => Promise<void>
}

const useSendEmail = (): UseSendEmail => {
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [success, setSuccess] = useState<boolean | null>(null)

  const validateEmail = (email: string) => {
    const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
    return re.test(email)
  }

  const sendEmail = async () => {
    if (!validateEmail(email)) {
      setStatus('Invalid email address')
      setSuccess(false)
      return
    }

    setStatus('')
    setLoading(true)

    try {
      const response = await fetch('/api/send-grid/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      if (!response.ok) {
        throw new Error(
          `Error sending email: ${response.status} ${response.statusText}`
        )
      }

      if (response.headers.get('content-type')?.includes('application/json')) {
        const data = await response.json()

        if (data.error) {
          setStatus('Error sending email: ' + data.error)
          setSuccess(false)
        } else {
          setStatus('Email sent successfully')
          setSuccess(true)
        }
      } else {
        throw new Error('Error sending email: Invalid content type')
      }
    } catch (error) {
      if (error instanceof Error) {
        setStatus('Error sending email: ' + error.message)
      } else {
        setStatus('Error sending email: An unknown error occurred')
      }
      setSuccess(false)
    }

    setLoading(false)
  }

  return { email, loading, status, success, setEmail, sendEmail }
}

export default useSendEmail
