import { SquiggleLink } from "@/components/shared/squiggle-link";
import { Box, Typography, useColorScheme, useTheme } from "basta-ui";
import Image from "next/image";
// tst
const BetaReleaseDate = () => {
  const theme = useTheme();
  const { mode } = useColorScheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
        [theme.breakpoints.up("md")]: {
          flexDirection: "row-reverse",
          gap: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "0 0 45%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "30ch" }}>
          <Typography
            level="h1"
            sx={{ fontSize: 64 /* HARDCODED VALUE, REMOVE LATER!!! */ }}
          >
            Beta
          </Typography>
          <Typography
            level="title-lg"
            fontWeight="bold"
            sx={{ fontSize: 24 /* HARDCODED VALUE, REMOVE LATER!!! */ }}
          >
            October 2023
          </Typography>
          <Box p={1} />
          <Typography level="body-lg">
            Basta is beta testing its world class auction tool with instagram
            sellers.
          </Typography>
          <Box p={1} />

          <Typography level="body-lg">
            Basta has already powered custom auctions for the likes of{" "}
            <SquiggleLink
              link={true}
              text="Pharrell Williams"
              level="title-md"
              darkModeColor="#D0ADF0"
              color="#551A8B"
              url="https://www.joopiter.com"
            />
            ,{" "}
            <SquiggleLink
              link={true}
              text="Ray Dalio"
              level="title-md"
              darkModeColor="#D0ADF0"
              color="#551A8B"
              url="https://auctions.davidlynchfoundation.org/ray-dalio"
            />
            ,{" "}
            <SquiggleLink
              link={true}
              text="Bill Ackman"
              level="title-md"
              darkModeColor="#D0ADF0"
              color="#551A8B"
              url="https://auctions.davidlynchfoundation.org/bill-ackman"
            />
            , the David Lynch Foundation, luxury real estate, and more.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "100%",
          minHeight: 400,
          position: "relative",
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            minHeight: 600,
          },
          [theme.breakpoints.up("lg")]: {
            width: "75%",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            borderRadius: "24px",
            display: "flex",
            height: 64,
            justifyContent: "center",
            left: "30%",
            padding: "8px",
            position: "absolute",
            top: "33%",
            width: 236,
            zIndex: 1,
            boxShadow: "0px 20px 68px 0px rgba(0, 0, 0, 0.13)",
            [theme.getColorSchemeSelector("light")]: {
              bgcolor: theme.palette.neutral["50"],
            },
            [theme.getColorSchemeSelector("dark")]: {
              bgcolor: theme.palette.common.black,
            },

            [theme.breakpoints.up("md")]: {
              height: 96,
              left: "30%",
              top: "30%",
              width: 323,
            },
          }}
        >
          <Typography
            level="body-sm"
            textAlign="center"
            sx={{
              [theme.breakpoints.up("md")]: {
                fontSize: "18px",
              } /* REMOVE THIS HARDCODED VALUE LATER */,
            }}
          >
            👇 MY AUCTION IS NOW LIVE 👇 basta.ai/auction/angelaromano
          </Typography>
        </Box>
        <Image
          src={"/assets/landing/iphoneinstagram.png"}
          fill
          style={{
            objectFit: "contain",
          }}
          alt="phone auction"
        />
      </Box>
    </Box>
  );
};

export default BetaReleaseDate;
