// Adding new palettes to theme docs: https://deploy-preview-35554--material-ui.netlify.app/joy-ui/customization/theme-colors/#adding-more-palettes
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {
  CssVarsProvider as JoyCssVarsProvider,
  extendTheme,
} from "@mui/joy/styles";
import { CssBaseline, CssVarsThemeOptions } from "@mui/joy";
import { bastaTheme } from "./custom/basta";
import React from "react";
import baseTheme from "./_base";

export type CustomizableTheme = Pick<
  CssVarsThemeOptions,
  "cssVarPrefix" | "fontFamily" | "colorSchemes"
>;

const materialTheme = materialExtendTheme();
const theme = extendTheme({ ...baseTheme, ...bastaTheme });
export const modeStorageKey = "basta-storage-mode";

const BastaThemeProvider = ({ children }: { children: React.JSX.Element }) => {
  return (
    <MaterialCssVarsProvider
      theme={{ [MATERIAL_THEME_ID]: materialTheme }}
      defaultMode="light"
    >
      <JoyCssVarsProvider
        theme={theme}
        defaultMode="light"
        modeStorageKey={modeStorageKey}
      >
        <CssBaseline />

        {children}
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default BastaThemeProvider;
