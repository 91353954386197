import { CustomizableTheme } from "..";
import { DM_Sans, DM_Mono } from "@next/font/google";

const dmSans = DM_Sans({ subsets: ["latin"], weight: ["400", "500", "700"] });
const dmMono = DM_Mono({ subsets: ["latin"], weight: ["300", "400", "500"] });

const cssVarPrefix = "basta";

const bastaPalette = {
  primary: {
    "50": "#ECF0F7",
    "100": "#D8E1EF",
    "200": "#B2C3DF",
    "300": "#8BA5D0",
    "400": "#6587C0",
    "500": "#3E69B0",
    "600": "#2B4A7B",
    "700": "#1F3558",
    "800": "#192A46",
    "900": "#18212F",
  },
  secondary: {
    "50": "#FDF9EC",
    "100": "#FDF9EC",
    "200": "#FBF4D9",
    "300": "#F6E8B4",
    "400": "#F2DD8E",
    "500": "#EDD169",
    "600": "#E9C643",
    "700": "#BA9E36",
    "800": "#8C7728",
    "900": "#2F280D",

    solidBg: `var(--${cssVarPrefix}-palette-secondary-400)`,
    solidActiveBg: `var(--${cssVarPrefix}-palette-secondary-500)`,
    outlinedBorder: `var(--${cssVarPrefix}-palette-secondary-500)`,
    outlinedColor: `var(--${cssVarPrefix}-palette-secondary-700)`,
    outlinedActiveBg: `var(--${cssVarPrefix}-palette-secondary-100)`,
    softColor: `var(--${cssVarPrefix}-palette-secondary-800)`,
    softBg: `var(--${cssVarPrefix}-palette-secondary-200)`,
    softActiveBg: `var(--${cssVarPrefix}-palette-secondary-300)`,
    plainColor: `var(--${cssVarPrefix}-palette-secondary-700)`,
    plainActiveBg: `var(--${cssVarPrefix}-palette-secondary-100)`,
  },

  common: {
    black: "#18212F",
    white: "#FFFFFF",
  },

  neutral: {
    "50": "#F8F8F9",
    "100": "#E8E9EA",
    "200": "#A3A6AC",
    "300": "#8B9097",
    "400": "#747A82",
    "500": "#5D646D",
    "600": "#464D59",
    "700": "#2F3744",
    "800": "#18212F",
    "900": "#111721",
  },
  danger: {
    "50": "#FDEFEB",
    "100": "#FBDED6",
    "200": "#F7BDAD",
    "300": "#F49D85",
    "400": "#F07C5C",
    "500": "#EC5B33",
    "600": "#BD4929",
    "700": "#8E371F",
    "800": "#5E2414",
    "900": "#2F120A",
  },
  info: {
    "50": "#ECF0F7",
    "100": "#D8E1EF",
    "200": "#B2C3DF",
    "300": "#8BA5D0",
    "400": "#6587C0",
    "500": "#3E69B0",
    "600": "#2B4A7B",
    "700": "#1F3558",
    "800": "#192A46",
    "900": "#18212F",
  },
  success: {
    "50": "#EEF2EF",
    "100": "#DCE5DF",
    "200": "#B9CCBE",
    "300": "#97B29E",
    "400": "#74997D",
    "500": "#517F5D",
    "600": "#41664A",
    "700": "#314C38",
    "800": "#203325",
    "900": "#101913",
  },
  warning: {
    "50": "#FDF9EC",
    "100": "#FBF4D9",
    "200": "#F6E8B4",
    "300": "#F2DD8E",
    "400": "#EDD169",
    "500": "#E9C643",
    "600": "#BA9E36",
    "700": "#8C7728",
    "800": "#5D4F1B",
    "900": "#2F280D",
  },
};

export const bastaTheme: CustomizableTheme = {
  cssVarPrefix: cssVarPrefix,

  fontFamily: {
    body: `${dmSans.style.fontFamily}, var(--${cssVarPrefix}-fontFamily-fallback)`,
    display: `${dmSans.style.fontFamily}, var(--${cssVarPrefix}-fontFamily-fallback)`,
    code: `${dmMono.style.fontFamily}, var(--${cssVarPrefix}-fontFamily-fallback)`,
    fallback: "Helvetica, Arial, sans-serif",
  },

  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        ...bastaPalette,

        background: {
          body: "#F8F8F9",
        },

        text: {
          tertiary: bastaPalette.common.black,
          secondary: bastaPalette.common.black,
        },

        gradients: {
          gradient1: {
            100: "linear-gradient(88.54deg, rgba(63, 106, 176, 0.1) 1.16%, rgba(237, 92, 52, 0.1) 96.57%)",
            200: "linear-gradient(88.54deg, rgba(63, 106, 176, 0.2) 1.16%, rgba(237, 92, 52, 0.2) 96.57%)",
            300: "linear-gradient(90deg, rgba(63, 106, 176, 0.4) 0%, rgba(237, 92, 52, 0.4) 100%)",
            400: "linear-gradient(90deg, rgba(63, 106, 176, 0.6) 0%, rgba(237, 92, 52, 0.6) 100%)",
            500: "linear-gradient(90deg, rgba(63, 106, 176, 0.8) 0%, rgba(237, 92, 52, 0.8) 100%)",
            600: "linear-gradient(90deg, #3F6AB0 0%, #ED5C34 100%)",
          },
          gradient2: {
            100: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.1) 17.77%, rgba(62, 105, 176, 0.1) 97.5%)",
            200: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.2) 17.77%, rgba(62, 105, 176, 0.2) 97.5%)",
            300: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.4) 17.77%, rgba(62, 105, 176, 0.4) 97.5%)",
            400: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.6) 17.77%, rgba(62, 105, 176, 0.6) 97.5%)",
            500: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.8) 17.77%, rgba(62, 105, 176, 0.8) 97.5%)",
            600: "linear-gradient(54.83deg, #517F5D 17.77%, #3E69B0 97.5%)",
          },
          gradient3: {
            100: "linear-gradient(90deg, rgba(236, 91, 51, 0.1) 0%, rgba(233, 198, 67, 0.1) 100%)",
            200: "linear-gradient(90deg, rgba(236, 91, 51, 0.2) 0%, rgba(233, 198, 67, 0.2) 100%)",
            300: "linear-gradient(90deg, rgba(236, 91, 51, 0.4) 0%, rgba(233, 198, 67, 0.4) 100%)",
            400: "linear-gradient(90deg, rgba(236, 91, 51, 0.6) 0%, rgba(233, 198, 67, 0.6) 100%)",
            500: "linear-gradient(90deg, rgba(236, 91, 51, 0.8) 0%, rgba(233, 198, 67, 0.8) 100%)",
            600: "linear-gradient(90deg, #EC5B33 0%, #E9C643 100%)",
          },
          gradient4: {
            100: "linear-gradient(81.15deg, rgba(25, 42, 70, 0.1) 0%, rgba(62, 105, 176, 0.1) 102.43%)",
            200: "linear-gradient(81.15deg, rgba(25, 42, 70, 0.2) 0%, rgba(62, 105, 176, 0.2) 102.43%)",
            300: "linear-gradient(81.15deg, rgba(25, 42, 70, 0.4) 0%, rgba(62, 105, 176, 0.4) 102.43%)",
            400: "linear-gradient(81.15deg, rgba(25, 42, 70, 0.6) 0%, rgba(62, 105, 176, 0.6) 102.43%)",
            500: "linear-gradient(81.15deg, rgba(25, 42, 70, 0.8) 0%, rgba(62, 105, 176, 0.8) 102.43%)",
            600: "linear-gradient(81.15deg, #192A46 0%, #3E69B0 102.43%);",
          },
          gradient5: {
            100: "linear-gradient(82.91deg, rgba(63, 106, 176, 0.1) 20.49%, rgba(178, 195, 223, 0.1) 96.99%)",
            200: "linear-gradient(82.91deg, rgba(63, 106, 176, 0.2) 20.49%, rgba(178, 195, 223, 0.2) 96.99%)",
            300: "linear-gradient(82.91deg, rgba(63, 106, 176, 0.4) 20.49%, rgba(178, 195, 223, 0.4) 96.99%)",
            400: "linear-gradient(82.91deg, rgba(63, 106, 176, 0.6) 20.49%, rgba(178, 195, 223, 0.6) 96.99%)",
            500: "linear-gradient(82.91deg, rgba(63, 106, 176, 0.8) 20.49%, rgba(178, 195, 223, 0.8) 96.99%)",
            600: "linear-gradient(82.91deg, #3F6AB0 20.49%, #B2C3DF 96.99%)",
          },
          gradient6: {
            100: "linear-gradient(81.32deg, rgba(233, 198, 67, 0.1) 9.8%, rgba(253, 249, 236, 0.1) 103.13%)",
            200: "linear-gradient(81.32deg, rgba(233, 198, 67, 0.2) 9.8%, rgba(253, 249, 236, 0.2) 103.13%)",
            300: "linear-gradient(81.32deg, rgba(233, 198, 67, 0.4) 9.8%, rgba(253, 249, 236, 0.4) 103.13%)",
            400: "linear-gradient(81.32deg, rgba(233, 198, 67, 0.7) 9.8%, rgba(253, 249, 236, 0.7) 103.13%)",
            500: "linear-gradient(81.32deg, rgba(233, 198, 67, 0.8) 9.8%, rgba(253, 249, 236, 0.8) 103.13%)",
            600: "linear-gradient(81.32deg, #E9C643 9.8%, #FDF9EC 103.13%)",
          },
          radial1: {
            100: "radial-gradient(63.53% 123.95% at 21.39% 24.72%, rgba(236, 91, 51, 0.2) 0%, rgba(62, 105, 176, 0.02) 85.42%, rgba(255, 255, 255, 0.088) 100%), radial-gradient(50.36% 69.32% at 73.4% 72.62%, rgba(62, 105, 176, 0.1512) 31.66%, rgba(81, 127, 93, 0.21) 80.21%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
            200: "radial-gradient(63.53% 123.95% at 21.39% 24.72%, rgba(236, 91, 51, 0.2) 0%, rgba(62, 105, 176, 0.02) 85.42%, rgba(255, 255, 255, 0.088) 100%), radial-gradient(50.36% 69.32% at 73.4% 72.62%, rgba(62, 105, 176, 0.1512) 31.66%, rgba(81, 127, 93, 0.21) 80.21%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
            300: "radial-gradient(63.53% 123.95% at 21.39% 24.72%, rgba(236, 91, 51, 0.2) 0%, rgba(62, 105, 176, 0.02) 85.42%, rgba(255, 255, 255, 0.088) 100%), radial-gradient(50.36% 69.32% at 73.4% 72.62%, rgba(62, 105, 176, 0.1512) 31.66%, rgba(81, 127, 93, 0.21) 80.21%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
            400: "radial-gradient(63.53% 123.95% at 21.39% 24.72%, rgba(236, 91, 51, 0.2) 0%, rgba(62, 105, 176, 0.02) 85.42%, rgba(255, 255, 255, 0.088) 100%), radial-gradient(50.36% 69.32% at 73.4% 72.62%, rgba(62, 105, 176, 0.1512) 31.66%, rgba(81, 127, 93, 0.21) 80.21%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
            500: "radial-gradient(63.53% 123.95% at 21.39% 24.72%, rgba(236, 91, 51, 0.2) 0%, rgba(62, 105, 176, 0.02) 85.42%, rgba(255, 255, 255, 0.088) 100%), radial-gradient(50.36% 69.32% at 73.4% 72.62%, rgba(62, 105, 176, 0.1512) 31.66%, rgba(81, 127, 93, 0.21) 80.21%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
            600: "radial-gradient(63.53% 123.95% at 21.39% 24.72%, rgba(236, 91, 51, 0.2) 0%, rgba(62, 105, 176, 0.02) 85.42%, rgba(255, 255, 255, 0.088) 100%), radial-gradient(50.36% 69.32% at 73.4% 72.62%, rgba(62, 105, 176, 0.1512) 31.66%, rgba(81, 127, 93, 0.21) 80.21%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
          },
        },
      },
    },
    dark: {
      palette: {
        mode: "dark",
        ...bastaPalette,
        text: {
          tertiary: bastaPalette.common.white,
          secondary: bastaPalette.common.white,
        },
        gradients: {
          gradient1: {
            100: "linear-gradient(88.54deg, rgba(63, 106, 176, 0.1) 1.16%, rgba(237, 92, 52, 0.1) 96.57%)",
            200: "linear-gradient(88.54deg, rgba(63, 106, 176, 0.2) 1.16%, rgba(237, 92, 52, 0.2) 96.57%)",
            300: "linear-gradient(90deg, rgba(63, 106, 176, 0.4) 0%, rgba(237, 92, 52, 0.4) 100%)",
            400: "linear-gradient(90deg, rgba(63, 106, 176, 0.6) 0%, rgba(237, 92, 52, 0.6) 100%)",
            500: "linear-gradient(90deg, rgba(63, 106, 176, 0.8) 0%, rgba(237, 92, 52, 0.8) 100%)",
            600: "linear-gradient(90deg, rgba(63, 106, 176, 1) 0%, rgba(237, 92, 52, 0.8) 100%)",
          },
          gradient2: {
            100: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.1) 17.77%, rgba(62, 105, 176, 0.1) 97.5%)",
            200: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.2) 17.77%, rgba(62, 105, 176, 0.2) 97.5%)",
            300: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.4) 17.77%, rgba(62, 105, 176, 0.4) 97.5%)",
            400: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.6) 17.77%, rgba(62, 105, 176, 0.6) 97.5%)",
            500: "linear-gradient(54.83deg, rgba(81, 127, 93, 0.8) 17.77%, rgba(62, 105, 176, 0.8) 97.5%)",
            600: "linear-gradient(54.83deg, rgba(81, 127, 93, 1) 17.77%, rgba(62, 105, 176, 0.8) 97.5%)",
          },
          radial1: {
            100: "radial-gradient(75.95% 131.57% at 24.27% 34.03%, rgba(236, 91, 51, 0.5) 0%, rgba(24, 33, 47, 0.22) 100%), radial-gradient(45.61% 51.91% at 81.32% 76.37%, rgba(81, 127, 93, 0.45) 0%, rgba(62, 105, 176, 0.45) 100%, rgba(62, 105, 176, 0) 100%), #18212F",
            200: "radial-gradient(75.95% 131.57% at 24.27% 34.03%, rgba(236, 91, 51, 0.5) 0%, rgba(24, 33, 47, 0.22) 100%), radial-gradient(45.61% 51.91% at 81.32% 76.37%, rgba(81, 127, 93, 0.45) 0%, rgba(62, 105, 176, 0.45) 100%, rgba(62, 105, 176, 0) 100%), #18212F",
            300: "radial-gradient(75.95% 131.57% at 24.27% 34.03%, rgba(236, 91, 51, 0.5) 0%, rgba(24, 33, 47, 0.22) 100%), radial-gradient(45.61% 51.91% at 81.32% 76.37%, rgba(81, 127, 93, 0.45) 0%, rgba(62, 105, 176, 0.45) 100%, rgba(62, 105, 176, 0) 100%), #18212F",
            400: "radial-gradient(75.95% 131.57% at 24.27% 34.03%, rgba(236, 91, 51, 0.5) 0%, rgba(24, 33, 47, 0.22) 100%), radial-gradient(45.61% 51.91% at 81.32% 76.37%, rgba(81, 127, 93, 0.45) 0%, rgba(62, 105, 176, 0.45) 100%, rgba(62, 105, 176, 0) 100%), #18212F",
            500: "radial-gradient(75.95% 131.57% at 24.27% 34.03%, rgba(236, 91, 51, 0.5) 0%, rgba(24, 33, 47, 0.22) 100%), radial-gradient(45.61% 51.91% at 81.32% 76.37%, rgba(81, 127, 93, 0.45) 0%, rgba(62, 105, 176, 0.45) 100%, rgba(62, 105, 176, 0) 100%), #18212F",
            600: "radial-gradient(75.95% 131.57% at 24.27% 34.03%, rgba(236, 91, 51, 0.5) 0%, rgba(24, 33, 47, 0.22) 100%), radial-gradient(45.61% 51.91% at 81.32% 76.37%, rgba(81, 127, 93, 0.45) 0%, rgba(62, 105, 176, 0.45) 100%, rgba(62, 105, 176, 0) 100%), #18212F",
          },
        },
      },
    },
  },
};
