import Image from "next/image";
import NextLink from "next/link";
import DarkImage from "../../../../public/assets/landing/phoneauction-dark.png";
import LightImage from "../../../../public/assets/landing/phoneauction-light.png";

import {
  ArrowDownIcon,
  Box,
  Button,
  Grid,
  Link,
  Typography,
  useColorScheme,
  useTheme,
} from "basta-ui";
import BastaFilled from "/public/assets/svg/basta_filled.svg";

const AuctionAnywhere = ({
  scrollToSignUp,
}: {
  scrollToSignUp: () => void;
}) => {
  const theme = useTheme();
  const { mode } = useColorScheme();

  return (
    <Grid container>
      <Grid xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box p={2} />
          <Link component={NextLink} href="/">
            <BastaFilled fill={theme.vars.palette.text.primary} height={24} />
          </Link>
          <Box p={7} />
          <Typography
            level="h1"
            sx={{ fontSize: 64 /* HARDCODED VALUE, REMOVE LATER!!! */ }}
          >
            Auction Anywhere
          </Typography>
          <Box p={2.5} />
          <Box>
            <Typography level="body-lg">
              A brand new e-commerce tool that lets anyone integrate branded,
              show-stopping auctions into their existing web platform or social
              media page.
            </Typography>
            <Box p={2.5} />
            <Button
              fullWidth
              onClick={scrollToSignUp}
              endDecorator={<ArrowDownIcon width={12} height={12} />}
              sx={{
                height: 64,
                fontSize: "18px", // !!!REMOVE THIS HARDCODED VALUE LATER!!!!
                [theme.breakpoints.up("sm")]: { width: 272 },
              }}
            >
              Sign up for a demo
            </Button>
            <Box p={2} />
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} md={8}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            minHeight: "350px",
            [theme.breakpoints.up("sm")]: { minHeight: "600px" },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "65%",
              [theme.breakpoints.up("sm")]: { width: "50%" },
              [theme.breakpoints.up("md")]: { width: "60%" },
            }}
          >
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <Box
                component="circle"
                cx="50"
                cy="50"
                r="50"
                sx={{
                  [theme.getColorSchemeSelector("light")]: {
                    fill: theme.palette.neutral["50"],
                  },
                  [theme.getColorSchemeSelector("dark")]: {
                    fill: theme.palette.common.black,
                  },
                }}
              />
            </svg>
          </Box>

          <Box
            component={Image}
            src={LightImage.src}
            fill
            alt="phone auction"
            sx={{
              objectFit: "contain",
              [theme.getColorSchemeSelector("light")]: {
                content: `url(${LightImage.src})`,
              },
              [theme.getColorSchemeSelector("dark")]: {
                content: `url(${DarkImage.src})`,
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuctionAnywhere;
