import Head from "next/head";
import { Fragment } from "react";
import { AppProps } from "next/app";
import { BastaThemeProvider } from "basta-ui";
import { SnackbarProvider } from "notistack";
import { closeSnackbar } from "notistack";
import { AdapterDayjs } from "basta-ui";
import { LocalizationProvider } from "basta-ui";

import "./global.css";
import { XMarkIcon } from "basta-ui";
import { LicenseInfo } from "basta-ui";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY!);

export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const Layout = (Component as any).layout || Fragment;

  return (
    <>
      <Head>
        <title>Basta Pages</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <BastaThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            action={(snackbarId) => (
              <XMarkIcon
                onClick={() => closeSnackbar(snackbarId)}
                width={24}
                height={24}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                }}
              />
            )}
          >
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </SnackbarProvider>
        </LocalizationProvider>
      </BastaThemeProvider>
    </>
  );
}
